import React, { useEffect } from "react";
import {
	Montserrat_400Regular,
	Montserrat_500Medium,
	Montserrat_600SemiBold,
	Montserrat_700Bold,
	useFonts,
} from "@expo-google-fonts/montserrat";
import { NavigationContainer, useNavigationContainerRef } from "@react-navigation/native";
import AppLoading from "expo-app-loading";
import { Provider } from "react-native-paper";
import { CartProvider } from "./src/providers/cart-provider";
import { CustomProvider } from "./src/providers/custom-provider";
import { ModalProvider } from "./src/providers/modal-provider";
import { NotificationBarProvider } from "./src/providers/notification-bar-provider";
import Routes from "./src/routes/routes";
import { SetUserProperties } from "./src/services/firebase";
import { ShellProvider } from "./src/providers/shell-provider";
import { SENTRY_DSN } from "./src/utils/contants";
import * as Sentry from '@sentry/react';
import pkg from "./app.json";
import { connectSocket } from "./src/services/socket";
import queryClient from "./src/store/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import 'react-lazy-load-image-component/src/effects/blur.css';

//
export default function App() {
	const navigationRef = useNavigationContainerRef();

	let [fontsLoaded] = useFonts({
		Montserrat_400Regular,
		Montserrat_500Medium,
		Montserrat_600SemiBold,
		Montserrat_700Bold,
	});

	useEffect(() => {
		SetUserProperties()
	}, []);

	Sentry.init({
		dsn: SENTRY_DSN,
		integrations: [
			new Sentry.BrowserTracing({
				tracePropagationTargets: [
					'localhost',
					/^https:\/\/api\.shoppbud\.com\.br/,
				],
			}),
			new Sentry.Integrations.TryCatch(),
			new Sentry.Integrations.GlobalHandlers({
				onerror: true,
				onunhandledrejection: true,
			}),
			new Sentry.Integrations.FunctionToString(),
		],
		beforeSend(event, hint) {
			if (event?.exception) {
				const { originalException } = hint || {};
				if (originalException && originalException?.constructor?.name === 'FirebaseError') {
					return null;
				}
			}

			return event;
		},
		release: pkg?.expo?.version,
		environment: "production",
		enableTracing: true,
		tracesSampleRate: 1.0,
		autoSessionTracking: true,
	});

	useEffect(() => {
	  connectSocket();
	}, [])


	if (!fontsLoaded) {
		return <AppLoading />;
	}

	return (
		<Provider>
			<CustomProvider>
				<NavigationContainer ref={navigationRef}>
					<NotificationBarProvider>
						<QueryClientProvider client={queryClient}>
							<ModalProvider>
								<CartProvider>
									<ShellProvider>
										<Routes />
									</ShellProvider>
								</CartProvider>
							</ModalProvider>
						</QueryClientProvider>
					</NotificationBarProvider>
				</NavigationContainer>
			</CustomProvider>
		</Provider>
	);
}
