import React, { useEffect, useState } from "react";
import { createContext, useContext, useMemo } from "react";

export interface BTDevice {
  id: string;
  isConnectable: boolean | null
  localName: null | string
  mtu: number
  name: null | string
  rssi: null | number
  txPowerLevel: null | number
}

type ShellContextData = {
  storeId: string;
  version: string;
  deviceId: string
  pos: {
    nearby: boolean;
    paired: boolean;
    authenticated: boolean;
    device: BTDevice | null;
    bluetoothAccessPermission: boolean;
    fileAccessPermission: boolean;
    locationAccessPermission: boolean;
    phoneAccessPermission: boolean;
  },
} | null;

const ShellContext = createContext<ShellContextData>(null);

export const ShellProvider = ({ children }: React.PropsWithChildren<{}>) => {

  const [state, setState] = useState<ShellContextData>(null as ShellContextData);

  useEffect(() => {
    if (window?.shell) setState(window.shell);

    const handleShellEvent = (e: Event) => {
      const event = e as CustomEvent;
      setState(event.detail);
    };

    // Adiciona o listener
    window.addEventListener("shell-event", handleShellEvent);

    return () => {
      // Remove o listener corretamente
      window.removeEventListener("shell-event", handleShellEvent);
    };
  }, []);

  return (
    <ShellContext.Provider value={state}>
      {children}
    </ShellContext.Provider>
  );
};


export const useShellData = () => {
  if (!ShellContext) {
    throw new Error("useShell must be used within a ShellProvider");
  }
  return useContext(ShellContext);
}
