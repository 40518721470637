import { useInfiniteQuery } from "@tanstack/react-query";

import { queryKeys } from "../../services/query";
import { apiSDK } from "../../services/apiSDK";

const perPage = 5;

export const buildUrl = (categoryName: string) => {
  switch (categoryName) {
    case "OFERTAS":
      return apiSDK.product.soldout;
    default:
      return apiSDK.product.productByCategory;
  }
};

export default function useProducts(
  storeId: number,
  categoryId: number,
  name: string
) {
  const queryFn = async ({ pageParam = 1 }: { pageParam?: number }) => {
    const { data, meta } = await buildUrl(name)({
      categoryId,
      page: pageParam,
      perPage,
      storeId,
    });

    return {
      data,
      meta,
    };
  };

  return useInfiniteQuery({
    enabled: !!storeId,
    getNextPageParam: (lastPage) => {
      if (lastPage.meta.current_page === lastPage.meta.last_page) {
        return undefined;
      }
      return lastPage.meta.current_page + 1;
    },
    queryFn,
    queryKey: queryKeys.PRODUCTS(storeId, categoryId, name),
    refetchOnWindowFocus: false,
  });
}
