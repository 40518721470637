import React from "react";
import { StyleProp, ViewStyle } from "react-native";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import { QuantityPicker } from "../QuantityPicker";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {
	Container,
	DescriptionContainer,
	DiscountIcon,
	Image,
	ImageContainer,
	ImageContent,
	RiskedPrice,
	PriceText,
	QuantityContainer,
	Title,
	ContainerMessage,
	Message,
} from "./styles";

const OfferIcon = require("../../../assets/offer.svg");

interface ProductProps {
	imgUrl: string;
	title: string;
	price_list: number;
	price_final: number;
	quantity: number;
	maxQuantity: number;
	onQuantityChange: (qnty: number) => void;
	quantityOpen?: boolean;
	onQuantityOpen: () => void;
	onQuantityPress: () => void;
	style?: StyleProp<ViewStyle>;
	onPress: () => void;
	testID?: string;
}

export const Product: React.FC<ProductProps> = ({
	imgUrl,
	title,
	price_list,
	price_final,
	quantity,
	maxQuantity,
	onQuantityChange,
	quantityOpen,
	onQuantityOpen,
	onQuantityPress,
	style,
	onPress,
	testID,
}) => {
	const inSale = price_final < price_list;

	return (
		<Container
			style={style}
			onPress={onPress}
			testID={`btn_product_${testID}`}
		>
			<ImageContainer>
				<ImageContent>
					<LazyLoadImage
						src={imgUrl}
						effect="blur"
						threshold={100}
						visibleByDefault={false}
						useIntersectionObserver={true}
						style={{ objectFit: 'contain', width: '100%', height: '100%' }}
					/>
				</ImageContent>
				{inSale && (
					<DiscountIcon
						testID={`product_${testID}_discount_icon`}
						source={OfferIcon}
					/>
				)}
				{maxQuantity <= 0 && (
					<ContainerMessage>
						<Message>
							Esse produto pode não estar em estoque
						</Message>
					</ContainerMessage>
				)}
				<QuantityContainer>
					<QuantityPicker
						type="compact"
						open={quantityOpen}
						value={quantity}
						maxValue={quantity + 1}
						onChange={onQuantityChange}
						onOpen={onQuantityOpen}
						onPricePress={onQuantityPress}
						testID={testID}
					/>
				</QuantityContainer>
			</ImageContainer>
			<DescriptionContainer>
				<Title
					numberOfLines={3}
					testID={`product_${testID}_title`}
				>
					{title}
				</Title>
				{inSale && (
					<RiskedPrice testID={`product_${testID}_old_price`}>
						{NumberToCurrency(price_list)}
					</RiskedPrice>
				)}
				<PriceText
					isDiscount={inSale}
					testID={`product_${testID}_price`}
				>
					{NumberToCurrency(price_final)}
				</PriceText>
			</DescriptionContainer>
		</Container>
	);
};
